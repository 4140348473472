@value tablet from "~res/breakpoints.module.css";

.planListContainer {
  margin-bottom: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, auto);
  width: 100%;
  max-width: 900px;
  margin: auto;
  @media tablet {
    overflow-x: scroll;
    grid-template-columns: repeat(14, auto);
    gap: 0;
    max-width: 768px;
    scroll-snap-type: x proximity;
  }
}

.planContainer {
  &:hover {
    background-color: var(--color-foreground-hover);
  }
  height: 410px;
  width: 280px;
  @media tablet {
    scroll-snap-align: start;
    margin: 5px;
  }
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0;
    font-weight: 450;
  }
}

.priceText {
  font-weight: 500;
  margin: 0;
  color: var(--color-link-secondary);
}

.planBasicDetailsContainer {
  text-align: center;
  margin-bottom: 1rem;
}

.planExtendedDetailsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  & > div {
    padding-top: 1rem;
  }
  ul {
    padding: 0 1rem;
    margin: 0;
    flex: 1;
  }
}

.selectPlanButtonContainer {
  width: 100%;
  padding-top: 1rem;
}

.seeAllPlansContainer {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

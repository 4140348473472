.container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  h1 {
    margin-top: 0;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  margin: 1rem 0;
}

.warning {
  font-weight: 500;
  color: var(--color-danger);
}

.loginButton {
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  height: 47px;
  svg.padlock {
    margin-left: 8px;
    margin-bottom: -2px;
  }
}

.forgotPasswordLink {
  float: right;
  margin-top: 13px;
  font-weight: 500;
}

@value tablet from "~res/breakpoints.module.css";
@value handheld from "~res/breakpoints.module.css";

.fixedWarningContainer {
  z-index: 3;
  height: 25px;
  background-color: #ffc107;
  padding: 2px;
  text-align: center;
}

.homeLink {
  display: none;
  @media handheld {
    display: block;
  }
}

.avatarContainer {
  display: flex;
  margin: 0 1rem;
  cursor: pointer;
  img {
    transition: box-shadow 0.2s;
  }
  &:hover > img {
    box-shadow: 0 0 8px var(--color-link-primary);
  }
  order: 3;

  @media handheld {
    order: initial;
    // There's extra padding in .header
    margin-left: 0;
  }
}

.shopLink {
  height: 35px;
  margin: 0 1rem;
  align-self: center;
  & > span {
    align-items: center;
    display: flex;
  }
  svg {
    margin-right: 5px;
    margin-bottom: 1px;
    @media handheld {
      display: none;
    }
  }
}

.container {
  z-index: 3;
  position: relative;
}

.header {
  height: 62px;
  display: flex;
  padding: 1rem 2rem;
  width: 100%;
  background: var(--color-navbar-bg); /* #eff1f3; */
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

  @media handheld {
    padding: 0 1rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
  a {
    display: flex;
    font-weight: 500;
  }
}

.headerContent {
  width: 100%;
  max-width: 1005px;
  display: flex;
  margin: 0 auto;
}

.headingContainer {
  margin: 0 0.5rem;
  display: flex;
  flex: 1;
  align-self: center;

  @media handheld {
    display: none;
  }
}

.navList {
  height: 62px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;

  li:last-child {
    margin-right: 12px;
  }

  li {
    cursor: pointer;
    position: relative;
    line-height: 20px;
    overflow: hidden;
    margin-right: 20px;
    @media tablet {
      margin-right: 20px;
    }
    a {
      align-self: center;
      color: rgb(159, 173, 189); /* color-text */
      display: block;
      padding: 22px 0;
      &:hover,
      &[aria-current="page"] {
        color: hsla(0, 0%, 100%, 0.88); /* color-text-lighter */
      }
      &:hover:after,
      &[aria-current="page"]:after {
        transform: translateY(0);
        color: #060f46;
      }
      &:after {
        transition: all 0.15s ease;
        content: "";
        position: absolute;
        bottom: 0;
        height: 3px;
        left: 0;
        right: 0;
        background-color: var(--color-link-primary);
        -webkit-transform: translateY(6px);
        transform: translateY(6px);
      }
    }
  }
}

.avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-self: center;
}

.nav {
  position: relative;
  align-self: center;
  @media handheld {
    display: flex;
    flex: 1;
    justify-content: space-around;
  }
}

@value handheld from "~res/breakpoints.module.css";

@keyframes skeleton {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.skeleton {
  overflow: hidden;
  background-color: var(--color-skeleton);
  background-image: linear-gradient(
    90deg,
    var(--color-skeleton),
    var(--color-skeleton-highlight),
    var(--color-skeleton)
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton 1.2s ease-in-out infinite;

  @media handheld {
    animation: none;
    background-image: none;
  }
}

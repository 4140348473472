.container {
  max-width: 450px;
  background: var(--color-foreground);
  padding: 1rem;
  align-self: center;
}

.formDetailsContainer {
  margin: 1rem 0;
}

.formGroup {
  display: flex;
  flex-direction: column;
  color: var(--color-text);
  margin: 1rem 0;
  & > label {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
  & > input {
    padding: 10px 5px;
    border-bottom: 1px dotted #424242;
    background: transparent;
    display: flex;
    flex-direction: column;
    flex: 1;
    outline: 0;
    border-radius: 0;
    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px var(--color-background) inset;
      -webkit-text-fill-color: var(--color-text);
      z-index: 0;
    }
  }
}

.selectedPlanContainer {
  display: flex;
  padding: 1rem;
  background: var(--color-foreground);
}

.selectedPlanText {
  display: flex;
  flex: 1;
}

.acceptTosContainer {
  display: flex;
  cursor: pointer;
  margin: 2rem 0;

  & > .svgContainer {
    margin-left: 5px;
    margin-right: 1rem;
    align-self: center;
    height: 20px;

    & > svg {
      align-self: center;
      width: 20px;
      height: 20px;
    }
  }
}

.subscribeButton {
  padding: 10px 20px;
  font-size: 1.2rem;
  width: 100%;
  height: 47px;
  .loadingContainer {
    width: 24px;
    height: 24px;
    margin: 0;
    color: #fff;
  }
}

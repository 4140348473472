.controls {
  padding: 10px;
  display: flex;
}

.icons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  svg {
    cursor: pointer;
    margin: 0 5px;
    height: 1.5rem;
    width: 1.5rem;
    color: var(--color-text);
    &:hover {
      color: var(--color-text-lighter);
    }
  }
}

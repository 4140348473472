.modalBackground {
  color: var(--color-text);
  background: var(--color-foreground);
  border-radius: 5px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
}

.contentContainer {
  padding: 1rem;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  button {
    margin: 0 5px;
  }
}

.titleBar {
  display: flex;
  border-radius: 5px 5px 0 0;

  .modalTitle {
    padding: 10px 1rem;
    flex: 1;
    margin: 0;
    font-weight: normal;
  }

  .closeButton {
    font-size: 2rem;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background: transparent;
    color: var(--color-link-primary);
    border: none;
    padding: 0;
    border-radius: 50%;
    line-height: 0;
    margin: 5px;
    transition: background-color 0.25s;
    &:hover,
    &:focus {
      background-color: rgba(#009fb7, 0.2);
      outline: none;
    }
  }
}

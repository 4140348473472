@value tablet from "~res/breakpoints.module.css";
@value handheld from "~res/breakpoints.module.css";

.container {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}

.youtubeContainer {
  height: 338px;
  padding: 0;

  @media handheld {
    height: 200px;
  }
}

.videoFeedContainer {
  max-width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  background: var(--color-foreground);
}

.videoFeedContainer::-webkit-scrollbar-thumb {
  height: 56px;
  background: hsla(0, 0%, 53.3%, 0.4);
}

.videoFeedContainer::-webkit-scrollbar {
  width: 8px;
}

.videoItemContainer {
  display: flex;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background: var(--color-foreground-hover);
  }
  &.active {
    background: var(--color-foreground-active);
  }
  .videoItemTitle {
    display: flex;
    flex: 1;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
}

.videoItemDate {
  font-size: 0.8rem;
}

.playlistItemNumber {
  min-width: 30px;
  max-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoThumbnail {
  width: 120px;
  height: 80%;
  align-self: center;
  @media handheld {
    width: 60px;
    height: 40px;
  }
}

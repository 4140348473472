@value handheld from "~res/breakpoints.module.css";

.footer {
  padding: 1rem;
  text-align: center;
  color: var(--color-text);
  height: 50px;
}

.container {
  width: 100%;
  padding-left: 230px;

  @media handheld {
    padding-left: 0;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
}

.container > div {
  width: 100%;
  max-width: 1400px;
  padding: 1rem;
  min-height: calc(100vh - 72px - 100px); /* navbar - footer */
  align-self: center;
}

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.reloadButton {
  align-self: center;
}

.message {
  font-size: 1.3rem;
}

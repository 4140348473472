@value tablet from "~res/breakpoints.module.css";

.grid {
  display: grid;
  grid-template-columns: minmax(400px, 640px) 350px;
  grid-column-gap: 1rem;
  align-self: center;

  @media tablet {
    grid-template-columns: auto;
  }
}

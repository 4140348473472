.bannerImage {
  width: 100%;
  min-height: 100px;
  max-height: 243px;
  justify-content: center;
  max-width: 1500px;
  object-fit: contain;
  display: flex;
  margin: auto;
  border-radius: 5px;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  animation: fadeIn 0.2s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalBody {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 400px;
  top: calc(50% - 150px);
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

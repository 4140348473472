.darkModeToggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  &:active > .toggleThumb {
    box-shadow: 0 0 5px 5px #ffa7c4;
  }
  align-self: center;
  margin-left: 1rem;
}

.toggleTrack {
  font-size: 1rem;
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #0f1114;
  transition: all 0.2s ease;
}

.toggleTrackCheck,
.toggleTrackX {
  position: absolute;
  opacity: 1;
  color: yellow;
  transition: opacity 0.25s ease;
  width: 17px;
  height: 23px;
  top: 3px;
  bottom: 0;
}

.toggleTrackCheck {
  left: 5px;
}

.toggleTrackX {
  right: 6px;
}

.toggleThumb {
  position: absolute;
  top: 1px;
  left: 1px;
  &.checked {
    left: 27px;
  }
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

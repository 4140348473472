@value tablet from "~res/breakpoints.module.css";

.container {
  max-width: 640px;
  margin: 0 auto;
}

.videoContainer {
  margin: 0;
  height: 360px;
  @media tablet {
    height: 190px;
  }
}

.video {
  width: 100%;
  height: 100%;
}

.cardContainer {
  padding: 1rem;
  background: var(--color-foreground);
}

.textarea {
  resize: vertical;
  flex: initial;
}

.buttonContainer {
  height: 34px;

  .loadingSpinner {
    width: 18px;
    height: 18px;
    margin: 0;
    color: #fff;
  }
}

.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease;
  background-color: var(--color-foreground);
  padding: 1rem;

  h2 {
    margin: 0;
  }
}

.card.nopadding {
  padding: 0;
}

.card.rounded {
  border-radius: 10px;
  overflow: hidden;
}

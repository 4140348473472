@value handheld from "~res/breakpoints.module.css";

.footer {
  padding: 1rem;
  color: var(--color-text);
  background-color: var(--color-foreground);

  .content {
    width: 100%;
    max-width: 900px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;

    h4 {
      color: var(--color-text);
      font-weight: 500;
    }

    @media handheld {
      grid-template-columns: 1fr;
      grid-row-gap: 1rem;
    }
  }
}

.socialMediaLinks {
  display: inline-flex;
  flex-direction: column;

  a {
    margin: 5px 0;
    display: flex;
    align-items: center;
    color: var(--color-text);

    &:hover {
      color: var(--color-text-lighter);
    }

    svg {
      margin-right: 5px;
    }
  }
}

.copyright {
  align-items: flex-end;
  display: flex;
  font-size: 0.8rem;
}

@value handheld from "~res/breakpoints.module.css";

.sidebar {
  z-index: 2;
  background-color: var(--color-sidebar-bg);
  width: 230px;
  transition: transform 0.25s ease;
  transform: translateX(230px);
  right: 0;

  &.left {
    right: initial;
    left: 0;
    transform: translateX(0);
  }

  &.right {
    right: 0;
  }

  &.open,
  .alwaysOpenDesktop {
    transform: translateX(0);
  }

  .homeLink {
    display: none;
  }

  @media handheld {
    right: initial;
    left: 0;
    transform: translateX(-230px);

    &.left {
      transform: translateX(-230px);
    }

    &.open {
      transform: translateX(0);
    }

    .homeLink {
      display: block;
    }
  }

  padding-bottom: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.sidebarContainer {
  position: absolute;
  top: 72px;
  bottom: 51px;
  width: 100%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

ul.nav {
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  li {
    a {
      svg {
        margin: 0 1rem;
        width: 24px;
        height: 24px;
      }
      color: var(--color-text);
      height: 48px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-weight: 500;
      padding-right: 8px;
      &:hover {
        background-color: var(--color-sidebar-link-active);
      }
      &[aria-current="page"] {
        background-color: var(--color-sidebar-link-active);
        color: var(--color-sidebar-link-active-text);
      }
    }
  }
}

.sidebarOverlay {
  &.alwaysOpenDesktop {
    display: none;
    @media handheld {
      display: block;
    }
  }

  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.divider {
  margin: 1rem 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.settingsContainer {
  display: flex;
  padding: 0 1rem;
  span {
    font-weight: 500;
    flex: 1;
  }
}

.signOutListItem {
  margin-top: 1rem;
}

.circularProgressContainer {
  animation: mui-progress-circular-rotate 1.4s linear infinite;
  color: var(--color-navbar-bg);
  display: inline-block;
  line-height: 1;
  margin: 16px;
  width: 40px;
  height: 40px;
}

.circularProgressCircle {
  animation: mui-progress-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
  stroke: currentColor;
}

@keyframes mui-progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mui-progress-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement:not(.PaymentRequestButton),
input {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #000;

  background: rgb(245, 244, 242);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(229, 227, 221);
}
.StripeElement input {
  color: var(--color-text-lighter) !important;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

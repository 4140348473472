.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
}

.backArrow {
  cursor: pointer;
  display: flex;
  font-weight: 500;
  align-items: center;
  margin: 1rem 0;
  &:hover {
    color: var(--color-text-lighter);
  }
}

.selectPlan {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" on;
  background-color: var(--color-background);
  transition: background-color 0.5s ease;
  height: 100%;
  overflow-x: hidden;
  min-height: 100%; /* 100vh;*/
  display: flex;
  flex: 1;
  color: var(--color-text);
  position: relative;
}

/* (For Admin sidebar) */
#root {
  min-height: 100vh;
}

/* colors based on https://coolors.co/272727-fed766-009fb7-696773-eff1f3 */
:root {
  --color-danger: #dc3545;
  --color-navbar-bg: #2b2d42;
  --color-sidebar-bg: #fff;
  --color-sidebar-link-hover: #fff;
  --color-sidebar-link-active: rgba(0, 0, 0, 0.04);
  --color-sidebar-link-active-text: #01579b;
  --color-link-primary: #009fb7;
  --color-link-primary-hover: #006f80;
  --color-link-secondary: #bb005c;
  --color-link-secondary-hover: #9c004d;
  /* --color-background: #fafafa; */
  --color-background: #ededed;
  --color-foreground: #fff;
  --color-foreground-hover: #ebebeb;
  --color-foreground-active: #e6e6e6;
  --color-bg-3: #696773;
  --color-text: #444;
  --color-text-light: #333;
  --color-text-lighter: #000;
  --media-desktop: 1024;
  --media-handheld: 600;
  --media-tablet: 768;
  --color-skeleton: #ddd;
  --color-skeleton-highlight: #f5f5f5;
}

body.dark-mode {
  --color-skeleton: #2b2d42;
  --color-skeleton-highlight: #444;
  --color-sidebar-bg: #474a65;
  --color-sidebar-link-hover: #1d203c;
  --color-sidebar-link-active: #1d203c;
  --color-sidebar-link-active-text: #fff;
  --color-background: rgb(19, 23, 29);
  --color-foreground: rgb(31, 35, 45);
  --color-foreground-hover: #494850;
  --color-foreground-active: #696773;
  --color-foreground-grey: rgb(25, 29, 38);
  --color-foreground-grey-dark: 16, 20, 25;
  --color-foreground-blue: 25, 29, 38;
  --color-foreground-blue-dark: 19, 23, 29;
  --color-text: rgb(159, 173, 189);
  --color-text-light: rgb(129, 140, 153);
  --color-text-lighter: hsla(0, 0%, 100%, 0.88);
}

a {
  color: var(--color-link-primary);
}
a:hover {
  color: var(--color-link-primary-hover);
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: var(--color-text-lighter);
}

h1 {
  font-size: 1.625rem;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@value tablet from "~res/breakpoints.module.css";

.container {
  margin: 1rem 0;
  width: 100%;
  transition: background-color 0.5s ease;

  & > span {
    display: flex;
  }
}

.videoContainer {
  height: 357px;
  width: 100%;
  @media tablet {
    height: 220px;
  }
}

.postDetailsContainer {
  padding: 1rem;
}

.smallInfoContainer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--color-text);
  font-weight: 500;
  font-size: 0.8rem;
}

.subscribeContainer {
  display: flex;
  background: var(--color-foreground-active);
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;

  svg {
    color: var(--color-text-lighter);
  }
}

.titleContainer {
  margin: 10px 0;
}

.title {
  color: var(--color-text-lighter);
  font-weight: 500;
  h2 {
    display: inline-block;
  }
  & > h2:hover {
    text-decoration: underline;
    color: var(--color-link-primary);
  }
}

.body {
  color: var(--color-text-light);
  margin: 10px 0;
}

.postImageContainer {
  overflow: hidden;
  height: 350px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

button.lockedButton {
  & > span {
    display: flex;
  }
  svg {
    margin-right: 5px;
  }
}

.recentPostsContainer {
  width: 100%;
  max-width: 640px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: center;

  .recentPostsTitle {
    margin-bottom: 0;
    margin-top: 4rem;
  }
}

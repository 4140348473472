.button {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  user-select: none;
  font-size: 1rem;
  color: var(--color-link-primary);
  background-color: currentColor;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px 0 rgba(62, 80, 104, 0.32);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.2s ease-in-out;

  &[disabled] {
    cursor: initial;
  }

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 1, 0, 0.3);
  }

  &.loading {
    padding: 6px 1rem;
    & > div {
      width: 16px;
      height: 16px;
      margin: 0;
      margin-top: 2px;
      svg {
        color: #fff;
      }
    }
  }

  &[disabled],
  &:hover,
  &:active {
    color: var(--color-link-primary-hover);
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--color-foreground), 0 0 0 3px currentColor;
  }

  &:active {
    box-shadow: inset 0 2px 8px 1px rgba(0, 0, 0, 0.2);
  }
  & > span {
    position: relative;
    color: #fff;
  }
}

.button.colorDanger {
  color: #f44336;
  &[disabled],
  &:hover,
  &:active {
    color: #f44336;
  }
}

.button.secondary {
  color: var(--color-link-secondary);
  &[disabled],
  &:hover,
  &:active {
    color: var(--color-link-secondary-hover);
  }
}

.button.fixed {
  height: 34px;
}

.button.outline {
  background-color: transparent;
  border: 1px solid var(--color-link-primary);
  color: var(--color-link-primary);

  & > span {
    color: var(--color-link-primary);
  }

  &:active > span {
    bottom: initial;
  }

  &:hover,
  &[disabled] {
    background-color: var(--color-link-primary-hover);
    & > span {
      color: #fff;
    }
  }

  &:focus {
    box-shadow: initial;
  }
}

.button.large {
  height: 40px;
  font-size: 1.2rem;
  width: 100%;
}

.button.xl {
  height: 50px;
  font-size: 1.5rem;
  width: 100%;
}

.button.fitContent {
  width: fit-content;
}

.button.pill {
  border-radius: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.input {
  padding: 10px 5px;
  height: 40px;
  border-bottom: 1px dotted #424242;
  flex: 1;
  outline: 0;
  border-radius: 0;

  &.error {
    background: rgba(206, 17, 38, 0.05);
    border-color: var(--color-danger);
  }

  &:focus {
    border-color: var(--color-link-primary);
  }

  background: rgb(245, 244, 242);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(229, 227, 221);
}

.focused {
  color: var(--color-link-primary);
}

small.error {
  color: var(--color-danger);
}
